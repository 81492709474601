import React, { Component } from 'react';
import {Row,Col} from "react-flexbox-grid";
import Container from 'react-bootstrap/Container';
import '../css/homepage.css';

class Homepage extends Component {

moveToEducation = () =>{ this.props.onRouteChange ('education');}

        render() {
                return (
                        <div >

                        <Row> 
                                <Col sm={6}  md={6}>  <img src={require('../images/smoke.jpg')} alt="" className='MainImg'  /></Col>
                                <Col sm={6}  md={6}>
                                <Container className='contMain'>
                                        <h1 className='MainText1'>JQ PORTFOLIO</h1>
                                        <h2 className='MainText2'>FRONTEND WEB DEVELOPER</h2>
                                                <p className='MainText3'>Hi there ! i'm a frontend web developer, with a Master Degree in Computer Science.
                                                I am returning from a carrer break  where i was caring  for my children. I am looking for new opportunities.
                                                </p>
                                        <button className='MainText4' onClick={this.moveToEducation}>Learn more </button>
                                </Container>
                                </Col>
                        </Row>
                        </div>
                );
                }
        }
export default Homepage;

