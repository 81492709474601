import React, { Component } from 'react';
import Navigation from './navigation';
import Container from 'react-bootstrap/Container';
import {Row,Col} from "react-flexbox-grid";
import '../css/recipe.css';


class Recipe extends Component {



render() {
    return (
      <div>

<Navigation onRouteChange={this.props.onRouteChange} />
<Container className="backgroundEducation">
 <h1 className="titleSection"> My Recipes</h1>
                  </Container>


{/* Line 1 */}
  <Row>
    <Col sm={4} md={4} className='tt'>
    <img src={require('../images/lemon tart.jpg')} alt=""  className='imgRecipe'/>

    <h3 className='titleRecipe'>Lemon Tart</h3>
    <li className='lineItems'>6 lemons</li>
    <li className='lineItems'>300g sugar</li>
    <li className='lineItems'>300ml double cream</li>
    <li className='lineItems'>9 eggs</li>
    <p  className='textRecipe'>Heat up the sugar, lemon juice and lemon zest inside a sauce pan.
Inside another pan heat up the double cream until ebullition then pour onto the lemon mix, then whisk everything together.
Beat all the eggs together then add them the cream, lemon and sugar mix.
Pour the final mix inside a tart case then cook it at 130°C until the tart mix is slightly wobbly.</p>
</Col>

<Col sm={4} md={4} className='tx'>
    <img src={require('../images/pecan tart.jpg')} alt=""  className='imgRecipe'/>

    <h3 className='titleRecipe'>Pecan Tart</h3>
    <li className='lineItems'>195g golden syrup</li>
    <li className='lineItems'>60g butter</li>
    <li className='lineItems'>1 tablespoon of vanilla essence</li>
    <li className='lineItems'>3 eggs</li>
    <li className='lineItems'>170g pecan nuts</li>
    <li className='lineItems'>1 tablespoon of cacao powder</li>
    <li className='lineItems'>3 tablespoon of sugar</li>

    <p  className='textRecipe'>Heat up the golden syrup, sugar and butter together inside a sauce pan.

Mix the eggs, vanilla essence and cacao powder together.

Pour the golden syrup mix onto the second mix then add the pecan nuts

Pour the final mix inside a tart case then cook it at 150°C until the tart is ready.</p>
</Col>



<Col sm={4} md={4} className='tt'>
    <img src={require('../images/choc tart.JPG')} alt=""  className='imgRecipe'/>

    <h3 className='titleRecipe'>Chocolate Tart</h3>

    <li className='lineItems'>400g dark chocolate 72%</li>
    <li className='lineItems'>400g butter</li>
    <li className='lineItems'>12 eggs</li>
    <li className='lineItems'>400g sugar</li>
  

    <p  className='textRecipe'>Melt the butter and sugar together

Beat the eggs and the sugar together until a thick texture is achieved ( use whisk and mixing bowl).

Mix all the ingredients together and poor them inside each tart.

Cook at 180°C for 8 to 9 mins</p>

<h3 className='titleRecipe'>Chocolate Sable</h3>
<li className='lineItems'>500g butter,&nbsp;500g icing sugar</li>
<li className='lineItems'>1050g plain flour</li>
<li className='lineItems'>200g cacao powder,&nbsp;5 eggs</li>
<p  className='textRecipe'>Put all the ingredients in a mixing bowl, and mix until ready.</p>
</Col>

  </Row>





{/* Line 2 */}


  <Row>
    <Col sm={4} md={4} className='tx'>
    <img src={require('../images/panaccotta.jpg')} alt=""  className='imgRecipe2'/>

    <h3 className='titleRecipe'>Vanilla Panacotta </h3>
    <li className='lineItems'>1120ml milk</li>
    <li className='lineItems'>1680ml double cream &nbsp;,720g sugar</li>
    <li className='lineItems'>4 vanilla pods seeds &nbsp;,12 leaf of gelatine</li>
  
    <p  className='textRecipe2'>Put the milk, cream, sugar, and vanilla pods inside a sauce pan than bring it to the boil.

Put the gelatine inside some cold water then when soft add them the mix inside the sauce pan. Stir well then pour inside the desired serving container.

The liquid on the top of the Panacotta and rhubarb is a coffee reduction.</p>

<br/>
<h3 className='titleRecipe'>Poached Rhubarb</h3>
<li className='lineItems'>3L orange juice&nbsp;,250ml grenadine</li>
<li className='lineItems'>3 Lemon grass stick &nbsp;,250g ginger</li>
<li className='lineItems'>500g sugar &nbsp;,2,5kg rhubarb</li>



<p  className='textRecipe2'>Cook the rhubarb inside the liquid mix at 170°C. keep checking how well the rhubarb is cooked every 2 to 3minutes .
 when the rhubarb is soft but still has a bite to it remove it from the liquid or it will continue to cook and end up mushy.</p>



</Col>

<Col sm={4} md={4} className='tt'>
    <img src={require('../images/vanilla chesse cake.jpg')} alt=""  className='imgRecipe2'/>

    <h3 className='titleRecipe'>Vanilla CheeseCake</h3>
    <li className='lineItems'>900g soft cheese</li>
    <li className='lineItems'>500g sugar</li>
    <li className='lineItems'>4 vanilla pods seeds</li>
    <li className='lineItems'>500ml crème fraiche</li>
    <li className='lineItems'>900ml double cream</li>
   

    <p  className='textRecipe2'>Put the soft cheese, crème fraiche, double cream and vanilla seed together inside a mixing bowl and beat it until the mix is thick.

Add the sugar to the mix and beat it all together for 20 to 30 seconds.

Pipe the cheese cake mix in metal rings or glasses in one long burst to avoid having gaps.

To serve add some powdered digestive biscuit or ginger bread on each side.</p>

<br/>
<h3 className='titleRecipe'>Raspberry Sorbet</h3>
<li className='lineItems'>1kg raspberry puree</li>
<li className='lineItems'>1L syrup</li>
<li className='lineItems'>1 pressed lemon</li>
<li className='lineItems'>6 tablespoon of yoghurt</li>

<p  className='textRecipe2'>Mix all the ingredient together then churn it inside an ice cream machine.</p>



</Col>



<Col sm={4} md={4} className='tx'>
    <img src={require('../images/sticky toffee.jpg')} alt=""  className='imgRecipe2'/>

    <h3 className='titleRecipe'>Sticky Toffee Pudding</h3>

    <li className='lineItems'>700g dates</li>
    <li className='lineItems'>1140ml water</li>
    <li className='lineItems'>700g sugar</li>
    <li className='lineItems'>250g butter</li>
    <li className='lineItems'>5 eggs</li>
    <li className='lineItems'>816g Self raising flour</li>
    <li className='lineItems'>2 tea spoon of bicarbonate</li>
    <li className='lineItems'>4 tea spoon of baking powder</li>
  

    <p  className='textRecipe2'>Put the dates and water inside a saucepan then warm it up until the dates are soft.

Put the eggs and sugar together and beat them until the eggs get a whiter shade. (Use a very large bowl as everything will be add there )

Melt the butter on its own ( in microwave will be fine ).<br/>
<br/>

When the dates are ready place them in a food processor and blitz them until you get a fine puree.

Pour the date puree into the eggs then mix well with the help of a Maryse. Add the butter and mix it with the Maryse.

<br/>
Add the self raising flour, bicarbonate, and baking powder and mix well until a smooth consistency is achieved.

pour the mix into greased ramequin, metal rings or baking tray then cook it at 170°C .</p>

<br/>
<h3 className='titleRecipe'>Caramel Sauce</h3>

<p  className='textRecipe2'>Place some sugar into a saucepan then cover it with around 5mm of water.

Let the sugar caramelise then add some double cream. Mix it well using a whisk.</p>
</Col>

  </Row>



       

    
        </div>
      );
  }
}
export default Recipe;