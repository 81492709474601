import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Navigation from './navigation';
import '../css/education.css';


import {Row,Col} from "react-flexbox-grid";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import '../css/carouselimages.css';

class Education extends Component {

moveToHomepage = () =>{ this.props.onRouteChange ('homepage');}
moveToCook = () =>{ this.props.onRouteChange ('cook');}

  render() {
       return (
              <div>
                <Navigation onRouteChange={this.props.onRouteChange} />

                  <Container className="blackSeparation">
                    <p className="blackSeparation" >black</p>
                  </Container>

                      {/* *********** EDUCATION & QUALIFICATIONS*************** */}
                  <Container className="backgroundEducation">
                    <h1 className="titleSection" onClick={this.moveToHomepage}> Education & Qualifications  </h1>
                  </Container>

                  <Row>
                      <Col sm={3} md={3} className='EduCol'> <h3>Establishment</h3></Col>
                      <Col sm={3} md={3} className='EduCol1'><h3>Period</h3></Col>
                      <Col sm={3} md={3} className='EduCol'><h3>Course</h3></Col>
                      <Col sm={3} md={3} className='EduCol1'><h3>Location</h3></Col>
                  </Row>

                  <Row>
                      <Col sm={3} md={3} className='EduCol1' ><a href="https://aru.ac.uk/study/undergraduate?utm_campaign=nn-ug-late-applications&utm_source=google&utm_medium=dsa-rsa&utm_content=lateapps-traditionalmature-growth&utm_term=anglia%20ruskin&campaign_id=19622768301&gclid=CjwKCAiAu5agBhBzEiwAdiR5tKUnBVuqLDaXefBQdvqEkGCXKzbMJrPQ_BCfjkfu6Dq0f0e634ZDdxoCcUkQAvD_BwE">
                      <img src={require('../images/ARU.png')} alt=""  className='imgEducationARU'/></a></Col>
                      <Col sm={3} md={3} className='EduCol'>September 2017 – October 2018</Col>
                      <Col sm={3} md={3} className='EduCol1'>I got a MSc with Merit (2:1 with 69% average ) in Computer Science</Col>
                      <Col sm={3} md={3} className='EduCol'>Cambridge</Col>
                  </Row>

                  <Row>
                      <Col sm={3} md={3} className='EduCol' ><a href="https://aru.ac.uk/study/undergraduate?utm_campaign=nn-ug-late-applications&utm_source=google&utm_medium=dsa-rsa&utm_content=lateapps-traditionalmature-growth&utm_term=anglia%20ruskin&campaign_id=19622768301&gclid=CjwKCAiAu5agBhBzEiwAdiR5tKUnBVuqLDaXefBQdvqEkGCXKzbMJrPQ_BCfjkfu6Dq0f0e634ZDdxoCcUkQAvD_BwE">
                      <img src={require('../images/ARU.png')} alt=""  className='imgEducationARU'/></a></Col>
                      <Col sm={3} md={3} className='EduCol1'>September 2014 – May 2017</Col>
                      <Col sm={3} md={3} className='EduCol'>I got a BEng with Honours (2:1 with 67.67% average)in Computer Science.</Col>
                      <Col sm={3} md={3} className='EduCol1'>Cambridge</Col>
                  </Row>

                  <Row>
                      <Col sm={3} md={3} className='EduCol2'><a href="https://www.trinitycollege.com/"><img src={require('../images/Trinity.png')} alt=""  className='imgEducationLondon'/></a> </Col>
                      <Col sm={3} md={3} className='EduCol'>September 2015</Col>
                      <Col sm={3} md={3} className='EduCol1'>Graded examination in spoken English, CEFR level B1.1 with Distinction</Col>
                      <Col sm={3} md={3} className='EduCol'>London</Col>
                  </Row>

                  <Row>
                      <Col sm={3} md={3} className='EduCol' ><a href="https://aru.ac.uk/study/undergraduate?utm_campaign=nn-ug-late-applications&utm_source=google&utm_medium=dsa-rsa&utm_content=lateapps-traditionalmature-growth&utm_term=anglia%20ruskin&campaign_id=19622768301&gclid=CjwKCAiAu5agBhBzEiwAdiR5tKUnBVuqLDaXefBQdvqEkGCXKzbMJrPQ_BCfjkfu6Dq0f0e634ZDdxoCcUkQAvD_BwE">
                      <img src={require('../images/ARU.png')} alt=""  className='imgEducationARU'/></a></Col>
                      <Col sm={3} md={3} className='EduCol1'>September 2013 – May 2014</Col>
                      <Col sm={3} md={3} className='EduCol'>I successfully completed a Foundation year in Computer Gaming with an average of 70%.</Col>
                      <Col sm={3} md={3} className='EduCol1'>Cambridge</Col>
                  </Row>

                  <Row>
                      <Col sm={3} md={3} className='EduCol1'> <a href="https://qualifications.pearson.com/en/about-us/qualification-brands/edi.html">< img src={require('../images/edi.png')} alt=""  className='imgEducationNVQ'/></a></Col>
                      <Col sm={3} md={3} className='EduCol'>2010 -2011</Col>
                      <Col sm={3} md={3} className='EduCol1'>I obtained an NVQ3 in Professional Cookery.</Col>
                      <Col sm={3} md={3} className='EduCol'>Cambridge</Col>
                  </Row>

                  <Container className="blackSeparation">
                      <p className="blackSeparation" >black</p>
                  </Container>

                    {/* ***** CARRER HISTORY **** */}
                  <Container className="backgroundEducation">
                      <h2 className="titleSection" onClick={this.moveToHomepage}>Career History</h2>
                  </Container>

                  <Row className='rowHist'>
                      <Col sm={2} md={2} className='EduCol'> <h3 className='titleHist'>Industry</h3></Col>
                      <Col sm={2} md={2} className='EduCol1'><h3 className='titleHist'>Job Title</h3></Col>
                      <Col sm={2} md={2} className='EduCol'><h3 className='titleHist'>Employer</h3></Col>
                      <Col sm={2} md={2} className='EduCol1'><h3 className='titleHist'>Period</h3></Col>
                      <Col sm={2} md={2} className='EduCol'><h3 className='titleHist'>Location</h3></Col>
                  </Row>

                  <Row className='rowHist'>
                      <Col sm={2} md={2} className='EduCol1' > <p  className='histContent1'>Computer Science</p></Col>
                      <Col sm={2} md={2} className='EduCol'><p  className='histContent1'>Web Developer</p></Col>
                      <Col sm={2} md={2} className='EduColdif1'><p  className='histContent'>EO Charging formally CamCom.Tech ( Company was taken over by EO Charging in February 2020)</p></Col>
                      <Col sm={2} md={2} className='EduCol'><p  className='histContent1'>September 2019 - May 2020</p></Col>
                      <Col sm={2} md={2} className='EduCol1'><p  className='histContent1'>Stowmarket - ENGLAND</p></Col>
                  </Row>

                  <Row className='rowHist'>
                      <Col sm={2} md={2} className='EduCol' >  <p  className='histContent1'>Catering</p></Col>
                      <Col sm={2} md={2} className='EduCol1'  onClick={this.moveToCook}><p className='histContent1' >Pastry chef</p> </Col>
                      <Col sm={2} md={2} className='EduColdif'> <p className='histContent3'>  
                      <li className="li" >Pembroke College</li>
                      <li className="li">Boxwood Cafe</li>
                      <li className="li"> Hotel du Vin</li>
                      <li className="li">Queen College</li></p>
                      </Col>
                      <Col sm={2} md={2} className='EduCol1'> <p className='histContent1'>2002 - 2012</p></Col>
                      <Col sm={2} md={2} className='EduCol'> <p className='histContent1'>ENGLAND</p></Col>
                  </Row>

                  <Container className="blackSeparation">
                  <p className="blackSeparation" >black</p>
                  </Container>


                  {/* *****************PROJECTS**************** */}
                  <Container className="backgroundEducation">
                    <h1 className="titleSection" onClick={this.moveToHomepage}> Projects</h1>
                  </Container>

                  <Row>
                      <Col sm={6} md={6} className='border' >
                          {/* red lodge fc project  */}
                            <Row> <p className="titleProjectsRedLodge">Red Lodge Football Club U13</p></Row>
                          {/* football website */}
                          <p className="projectsDescriptionsF"> I have made this website for the football club of red lodge youth team under 13 as my middle son joined the team and i knew
                          that they did not have a website. i used the knowlege that i got from CamComTech to create and design this website completly by myself. 
                          </p> 
                              <Col  className="colProject1" md={4}lg={4}><img src={require('../images/redLodgeFC.jpg')} alt="" className="footballImage"></img></Col>
                      </Col>

                      <Col  sm={6} md={6}  className='border2'>
                          {/* camcom website  */}
                             <h2 className="titleProjects2"> CamCom.tech website</h2>
                              <p className="Framework">Framework: Microsoft Visual Code</p>
                              <p className="languages">Languages : JSX with React JS Libraries, HTML5, CSS, React Bootstrap.</p>
                              <p className="projectsDescriptions">Whilst being employed by CamCom.Tech I was asked to re-create their website following the designs of an external company.
                              The website was never finished with the actual content because the development team at CamCom.Tech was taken over by 
                              EO Charging and this project was not continued</p> 
                          <Col  className="colProject1" md={3}lg={3}><img src={require('../images/camcomWebsite/Camcom1.png')} alt="" className="imageCamcom"></img></Col>
                      </Col>
                  </Row>

                  <Row   sm={12} md={12}  className='border3'>
                      {/* converter project  */}
                          <h2 className="titleProjectsConvertor"> Converter</h2>
                            <p className="projectsDescriptions">The Project below  was created simply to improve my programming skills. The application was almost ready for realease on the Google store but I ran
                            into a problem whilst trying to get an APK key. I then got my Job with CamCom Tech and never got to finish it.But the experience was nice.
                            </p>  
                               <p className="Framework">Framework: Laravel</p>
                                 <p className="languages">Languages: Javascript, HTML5, CSS, Bootstrap.</p>
                            <Row>
                                <Col  className="colProject1" md={3}lg={3}><img src={require('../images/converter/converter1.png')} alt="" className="imageconverter"></img></Col>
                                <Col  className="colProject1" md={3}lg={3}> <img src={require('../images/converter/converter2.png')} alt="" className="imageconverter"></img></Col>
                                <Col  className="colProject1" md={3}lg={3}>  <img src={require('../images/converter/converter3.png')} alt="" className="imageconverter"></img></Col>
                                <Col  className="colProject1" md={3}lg={3}> <img src={require('../images/converter/converter4.png')} alt="" className="imageconverter"></img></Col>
                            </Row>
                  </Row>

                  <Container className="blackSeparation">
                  <p className="blackSeparation" >black</p>
                  </Container>

                  {/* *********************HOBBIES AND INTERSTS********************* */}
                  <Container className="backgroundEducation">
                      <h1 className="titleSection" onClick={this.moveToHomepage}>Hobbies and Interests</h1>
                  </Container> 

                  <Row sm={12} md={12}  className='border2'>
                      <Container className="containercarousel">
                          <Row>
                              <Col sm={3} md={3} lg={3}>
                                <h2 class="titlecategorie"   >Video Games</h2>
                                      <Carousel  showArrows={false} infiniteLoop autoPlay swipeable={false}    showIndicators={false}  showStatus={false} interval={3000} showThumbs={false}>
                                          <div className="diplaysizecarousel" ><img src={require('../images/dragonquest8.png')} alt="" className="imagescarousel" ></img></div> 
                                          <div className="diplaysizecarousel"  ><img src={require('../images/ff7.png')} alt="" className="imagescarousel"></img></div> 
                                          <div className="diplaysizecarousel" ><img src={require('../images/dmc.jpg')} alt="" className="imagescarousel"></img></div> 
                                          <div className="diplaysizecarousel" ><img src={require('../images/ACBrotherhood.jpg')} alt="" className="imagescarousel"></img></div> 
                                          <div className="diplaysizecarousel" ><img src={require('../images/thelastoUs.jpg')} alt="" className="imagescarousel"></img></div> 
                                          <div className="diplaysizecarousel" ><img src={require('../images/borderland2.jpg')} alt="" className="imagescarousel"></img></div> 
                                          <div className="diplaysizecarousel" ><img src={require('../images/nfpmw.jpg')} alt="" className="imagescarousel"></img></div> 
                                          <div className="diplaysizecarousel" ><img src={require('../images/residentevil4.jpg')} alt="" className="imagescarousel"></img></div> 
                                          <div className="diplaysizecarousel" ><img src={require('../images/modernWarfare2.jpg')} alt="" className="imagescarousel"></img></div> 
                                          <div className="diplaysizecarousel" ><img src={require('../images/diablo.jpg')} alt="" className="imagescarousel"></img></div> 
                                      </Carousel>
                              </Col>

                              <Col sm={3} md={3} lg={3}>
                                  <h2 class="titlecategorie">Food</h2>
                                      <Carousel  showArrows={false} infiniteLoop autoPlay swipeable={false}    showIndicators={false}  showStatus={false} interval={3000} showThumbs={false}>
                                          <div className="diplaysizecarousel" ><img src={require('../images/pasta.jpg')} alt="" className="imagescarousel" ></img></div> 
                                          <div className="diplaysizecarousel"  ><img src={require('../images/burger.jpg')} alt="" className="imagescarousel"></img></div> 
                                          <div className="diplaysizecarousel" ><img src={require('../images/escargot.jpg')} alt="" className="imagescarousel"></img></div> 
                                          <div className="diplaysizecarousel" ><img src={require('../images/chocolateCake.jpg')} alt="" className="imagescarousel"></img></div> 
                                          <div className="diplaysizecarousel" ><img src={require('../images/raclettes.png')} alt="" className="imagescarousel"></img></div> 
                                      </Carousel>
                              </Col>

                              <Col sm={3} md={3} lg={3}>
                                  <h2 class="titlecategorie">Movies</h2>
                                      <Carousel  showArrows={false} infiniteLoop autoPlay swipeable={false}    showIndicators={false}  showStatus={false} interval={3000} showThumbs={false}>
                                          <div className="diplaysizecarousel" ><img src={require('../images/last-samurai.jpg')} alt="" className="imagescarousel" ></img></div> 
                                          <div className="diplaysizecarousel"  ><img src={require('../images/theGreenMile.jpg')} alt="" className="imagescarousel"></img></div> 
                                          <div className="diplaysizecarousel" ><img src={require('../images/the36chamberofShaolin.jpg')} alt="" className="imagescarousel"></img></div> 
                                          <div className="diplaysizecarousel" ><img src={require('../images/Armageddon.jpg')} alt="" className="imagescarousel"></img></div> 
                                          <div className="diplaysizecarousel" ><img src={require('../images/the-wolf-of-wall-street-1024x576.jpg')} alt="" className="imagescarousel"></img></div> 
                                          <div className="diplaysizecarousel" ><img src={require('../images/captainAmericacivilWar.jpg')} alt="" className="imagescarousel"></img></div> 
                                          <div className="diplaysizecarousel" ><img src={require('../images/starWarsTheRevenge.jpg')} alt="" className="imagescarousel"></img></div> 
                                          <div className="diplaysizecarousel" ><img src={require('../images/coachCarter.jpg')} alt="" className="imagescarousel"></img></div> 
                                          <div className="diplaysizecarousel" ><img src={require('../images/rememberTheTitans.png')} alt="" className="imagescarousel"></img></div> 
                                          <div className="diplaysizecarousel" ><img src={require('../images/titanic.jpg')} alt="" className="imagescarousel"></img></div> 
                                          <div className="diplaysizecarousel" ><img src={require('../images/ThedarkKnight.jpg')} alt="" className="imagescarousel"></img></div> 
                                      </Carousel>
                              </Col>

                              <Col sm={3} md={3} lg={3}>
                                  <h2 class="titlecategorie">Sport</h2>
                                      <Carousel  showArrows={false} infiniteLoop autoPlay swipeable={false}    showIndicators={false}  showStatus={false} interval={3000} showThumbs={false}>
                                          <div className="diplaysizecarousel" ><img src={require('../images/martialarts.jpg')} alt="" className="imagescarousel" ></img></div> 
                                          <div className="diplaysizecarousel"  ><img src={require('../images/football.jpg')} alt="" className="imagescarousel"></img></div> 
                                      </Carousel>
                              </Col>

                          </Row>

                      </Container>

                  </Row>

              </div>

           );

      }
  }
export default Education;