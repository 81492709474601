import React, { Component } from 'react';
import Navigation from './navigation';
import Container from 'react-bootstrap/Container';
import {Row,Col} from "react-flexbox-grid";
import '../css/tarts.css';

class HomeMade extends Component {

    moveToCook = () =>{ this.props.onRouteChange ('cook');}
    
    render() {
                return (
                          <div>
                              <Navigation onRouteChange={this.props.onRouteChange} />
                              <Container className="backgroundEducation"></Container>

                                  <Container className='tartContainer'>
                                  <h1 className='homeMadeMeals'onClick={this.moveToCook}>Home-Made Meals</h1>
                                      <Row>
                                          <Col className='coltarts'  sm={3} md={3}><img src={require('../images/meal1.jpg')} alt=""  className='TartSweet'/></Col>
                                          <Col className='coltarts'  sm={3} md={3}><img src={require('../images/meal2.jpg')} alt=""  className='TartSweet'/></Col>
                                          <Col className='coltarts'  sm={3} md={3}><img src={require('../images/meal3.jpg')} alt=""  className='TartSweet'/></Col>
                                          <Col className='coltarts'  sm={3} md={3}><img src={require('../images/meal4.jpg')} alt=""  className='TartSweet'/></Col>
                                      </Row>
                                      <Row>
                                          <Col className='coltarts'  sm={3} md={3}><img src={require('../images/meal5.jpg')} alt=""  className='TartSweet'/></Col>
                                          <Col className='coltarts'  sm={3} md={3}><img src={require('../images/meal6.jpg')} alt=""  className='TartSweet'/></Col>
                                          <Col className='coltarts'  sm={3} md={3}><img src={require('../images/meal7.jpg')} alt=""  className='TartSweet'/></Col>
                                          <Col className='coltarts'  sm={3} md={3}><img src={require('../images/meal8.jpg')} alt=""  className='TartSweet'/></Col>
                                      </Row>
                                      <Row>
                                          <Col className='coltarts'  sm={3} md={3}><img src={require('../images/meal9.jpg')} alt=""  className='TartSweet'/></Col>
                                          <Col className='coltarts'  sm={3} md={3}><img src={require('../images/meal10.png')} alt=""  className='TartSweet'/></Col>
                                          <Col className='coltarts'  sm={3} md={3}><img src={require('../images/meal11.jpg')} alt=""  className='TartSweet'/></Col>
                                          <Col className='coltarts'  sm={3} md={3}><img src={require('../images/meal12.jpg')} alt=""  className='TartSweet'/></Col>
                                      </Row>
                                  </Container>
                          </div>
                        );
                }
              }
export default HomeMade;