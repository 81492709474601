import React, { Component } from 'react';
import Navigation from './navigation';
import Container from 'react-bootstrap/Container';
import {Row,Col} from "react-flexbox-grid";
import '../css/tarts.css';

class Tarts extends Component {

    moveToCook = () =>{ this.props.onRouteChange ('cook');}

      render() {
                return (
                          <div>
                              <Navigation onRouteChange={this.props.onRouteChange} />
                              <Container className="backgroundEducation"></Container>

                              <Container className='tartContainer'>
                              <h1 className='titleTarts' onClick={this.moveToCook}>Tarts</h1>
                                  <Row>
                                      <Col className='coltarts'  sm={4} md={4}><img src={require('../images/choc tart.JPG')} alt=""  className='TartSweet'/></Col>
                                      <Col   className='coltarts' sm={4} md={4}><img src={require('../images/pecan tart.jpg')} alt=""  className='TartSweet'/></Col>
                                      <Col className='coltarts' sm={4} md={4}><img src={require('../images/lemon tart.jpg')} alt=""  className='TartSweet'/></Col>
                                  </Row>
                                  <Row>
                                      <Col className='coltarts' sm={4} md={4}><img src={require('../images/custard Tart.jpg')} alt=""  className='TartSweet'/></Col>
                                      <Col className='coltarts' sm={4} md={4}><img src={require('../images/tartTatin.jpg')} alt=""  className='TartSweet'/></Col>
                                      <Col className='coltarts' sm={4} md={4}><img src={require('../images/tartFine.jpg')} alt=""  className='TartSweet'/></Col>
                                  </Row>
                              </Container>
                          </div>
                        );
                }
      }
export default Tarts;