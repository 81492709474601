import React, { Component } from 'react';
import '../css/music.css';
class Music extends Component {

render() {
    return (
      <div>
         
                   <video className='videoHomepage' controls={false} autoplay="true" preload={'auto'}  loop="true"      >
      <source src={require('../images/give-a-little.mp3')} type="audio/mp3"   />
     </video>
    

    
        </div>
      );
  }
}
export default Music;