import React, { Component } from 'react';
import Navigation from './navigation';
import Container from 'react-bootstrap/Container';
import '../css/cook.css';

class Cook extends Component {

moveToTarts = () =>{ this.props.onRouteChange ('tarts');}
moveToPuddings = () =>{ this.props.onRouteChange ('puddings');}
moveToHomeMade = () =>{ this.props.onRouteChange ('homeMade');}
moveToHomepage = () =>{ this.props.onRouteChange ('homepage');}

changeurl = () =>{ this.props.replaceState({page:'tarts'},'cook','/tarts');

}
    render() {
            return (
                    <div>
                        <Navigation onRouteChange={this.props.onRouteChange} />
                        <Container className="backgroundEducation"></Container>

                        <img src={require('../images/dessertMenu.jpg')} alt=""  className='imgRecipeMenu'></img>
                        <h1 className='titleCook'  onClick={this.moveToHomepage}>My Recipes</h1>

                        <button className='Tarts' onClick={this.moveToTarts }  pushState={this.changeurl }  > Tarts</button>  
                            <button className='Puddings' onClick={this.moveToPuddings}> Puddings</button>  
                               <button className='IC'> Ice Creams</button>  
                                   <button className='HomeMeals' onClick={this.moveToHomeMade}> Home Meals</button>  

                          <audio className='videoHomepage' controls={false} autoplay="true" preload={'auto'}  loop="true">
                             <source src={require('../images/give-a-little.mp3')} type="audio/mp3"   />
                          </audio>
                    </div>
                    );
            }
    }
export default Cook;