    import React from 'react';
    import Homepage from './../components/homepage';
    import Education from './../components/education';
    import Navigation from './../components/navigation';

     import Recipe from './../components/recipe';
     import Cook from './../components/cook';
     import Tarts from './../components/tarts';
     import Puddings from './../components/puddings';
     import HomeMade from './../components/homeMade';
     import Music from './../components/music';

    import '../App.css';

    class App extends React.Component {
      constructor (props){
        super(props)
        this.state = {route: 'homepage',}
      }
      
      onRouteChange = (newRoute)=>{
        this.setState({route:newRoute});
      }
      
      render() {
        const{route} = this.state;
          return (
          <div >
            { route ==='homepage'? <Homepage onRouteChange = {this.onRouteChange}/> : <div></div>    }
            { route ==='education'? <Education onRouteChange = {this.onRouteChange}/> : <div></div>  }
            { route ==='navigation'? <Navigation onRouteChange = {this.onRouteChange}/> : <div></div>  }
           
       
            { route ==='recipe'?<Recipe onRouteChange = {this.onRouteChange}/> : <div></div>  }
            { route ==='cook'?<Cook onRouteChange = {this.onRouteChange}/> : <div></div>  }
            { route ==='tarts'?<Tarts onRouteChange = {this.onRouteChange}/> : <div></div>  }
            { route ==='puddings'?<Puddings onRouteChange = {this.onRouteChange}/> : <div></div>  }
            { route ==='homeMade'?<HomeMade onRouteChange = {this.onRouteChange}/> : <div></div>  }
            { route ==='music'?<Music onRouteChange = {this.onRouteChange}/> : <div></div>  }
         
          </div>    
        );
    }
}
export default App;

