import React, { Component } from 'react';

import '../css/navigation.css';
class Navigation extends Component {
    
    moveToHomepage = () =>{ this.props.onRouteChange ('homepage');}
 

    render() {
        return (
            <div>
          
               
            </div>
            );
        }
    }
export default Navigation;



