import React, { Component } from 'react';
import Navigation from './navigation';
import Container from 'react-bootstrap/Container';
import {Row,Col} from "react-flexbox-grid";
import '../css/tarts.css';


class Puddings extends Component {
  moveToCook = () =>{ this.props.onRouteChange ('cook');}


render() {
    return (
      <div>

<Navigation onRouteChange={this.props.onRouteChange} />
<Container className="backgroundEducation">

                  </Container>

                <Container className='tartContainer'>

<h1 className='titleTarts' onClick={this.moveToCook}>Puddings</h1>


<Row>
  <Col className='coltarts'  sm={3} md={3}><img src={require('../images/brownie.jpg')} alt=""  className='TartSweet'/></Col>
  <Col   className='coltarts' sm={3} md={3}><img src={require('../images/choc fondant.jpg')} alt=""  className='TartSweet'/></Col>
  <Col className='coltarts' sm={3} md={3}><img src={require('../images/chocolate marquise.jpg')} alt=""  className='TartSweet'/></Col>
  <Col className='coltarts' sm={3} md={3}><img src={require('../images/creme brulee.png')} alt=""  className='TartSweet'/></Col>
</Row>

<Row>
  <Col className='coltarts'  sm={3} md={3}><img src={require('../images/eton mess.jpg')} alt=""  className='TartSweet'/></Col>
  <Col   className='coltarts' sm={3} md={3}><img src={require('../images/lemon posset.jpg')} alt=""  className='TartSweet'/></Col>
  <Col className='coltarts' sm={3} md={3}><img src={require('../images/panaccotta.jpg')} alt=""  className='TartSweet'/></Col>
  <Col className='coltarts' sm={3} md={3}><img src={require('../images/Pavlova.jpg')} alt=""  className='TartSweet'/></Col>
</Row>

<Row>
  <Col className='coltarts'  sm={3} md={3}><img src={require('../images/rice pudding.jpg')} alt=""  className='TartSweet'/></Col>
  <Col   className='coltarts' sm={3} md={3}><img src={require('../images/sticky toffee.jpg')} alt=""  className='TartSweet'/></Col>
  <Col className='coltarts' sm={3} md={3}><img src={require('../images/sundae.jpg')} alt=""  className='TartSweet'/></Col>
  <Col className='coltarts' sm={3} md={3}><img src={require('../images/Profiteroles.jpg')} alt=""  className='TartSweet'/></Col>
</Row>

<Row>
  <Col className='coltarts'  sm={3} md={3}><img src={require('../images/vanilla chesse cake.jpg')} alt=""  className='TartSweet'/></Col>

</Row>




               




                </Container>
   


   
                   

    
        </div>
      );
  }
}
export default Puddings;